/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./index.css";
import { removeCheckoutData } from "../../../Helpers/checkout";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Index() {
  const empresa = process.env.REACT_APP_NAME_EMPRESA;
  const { hide } = useZendesk();
  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
    removeCheckoutData();
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        <h1>Políticas de compra</h1>
        <p>
          Conforme o Código de Defesa do Consumidor (Artigo 49), somente serão
          aceitos pedidos de cancelamentos de compras solicitados até 7 dias
          após o pedido, desde que não ultrapasse o limite de 48hrs antes do
          início do evento. <br />
        </p>

        <h4>
          <strong>Cancelamentos, Trocas de Horários e Datas de Eventos</strong>
        </h4>

        <p>
          Em casos de cancelamento, troca de horário e data do evento, é direito
          do cliente solicitar a devolução do dinheiro referente aos ingressos
          do evento em questão. Clientes que compraram seus ingressos através do
          site da {empresa}, devem entrar em contato pelo Fale Conosco ou por
          e-mail solicitando a devolução.
        </p>

        <h4>
          <strong>Taxa de Serviço</strong>
        </h4>
        <p>
          Dependendo do contrato firmado com a organização de cada evento, a{" "}
          {empresa} poderá realizar a cobrança uma taxa de serviço por cada
          ingresso adquirido, a qual será incluída no preço total a ser pago
          pelo USUÁRIO, podendo variar conforme a complexidade de cada evento. A
          taxa de serviço será definida a critério da equipe , servindo apenas
          para cobrir os custos do serviço prestado, e guardará o mais perfeito
          equilíbrio entre o preço do ingresso e a conveniência disponibilizada
          ao USUÁRIO
        </p>

        <h4>
          <strong>POLÍTICA DE PRIVACIDADE</strong>
        </h4>
        <p>
          Este site é mantido e operado por {empresa}
          <br></br>
          Nós coletamos e utilizamos alguns dados pessoais que pertencem àqueles
          que utilizam nosso site. Ao fazê-lo, agimos na qualidade de
          controlador desses dados e estamos sujeitos às disposições da Lei
          Federal n. 13.709/2018 (Lei Geral de Proteção de Dados Pessoais -
          LGPD)
          <br></br>
          Nós cuidamos da proteção de seus dados pessoais e, por isso,
          disponibilizamos esta política de privacidade, que contém informações
          importantes sobre:
          <br></br>- Quem deve utilizar nosso site<br></br>- Quais dados
          coletamos e o que fazemos com eles; <br></br>- Seus direitos em
          relação aos seus dados pessoais; e <br></br>- Como entrar em contato
          conosco.
          <br></br>
          Afinal, o que é tratamento de dados?
          <br></br>É toda e qualquer operação realizada com um dado: coleta,
          produção, recepção, classificação, utilização, acesso, reprodução,
          transmissão, distribuição, processamento, arquivamento, armazenamento,
          eliminação, avaliação ou controle da informação, modificação,
          comunicação, transferência, difusão ou extração. Ou seja, se há um
          dado, alguma ação está sendo feita com ele. Dessa forma, podemos
          entender o tratamento de dados como um ciclo de vida das informações.
          <br></br>
          Dado pessoal
          <br></br>
          Na LGPD é definido como “informação relacionada à pessoa identificada
          ou identificável”. Ou seja, quando ele permite a identificação, direta
          ou indireta, de uma pessoa (titular), como por exemplo: nome,
          sobrenome, data de nascimento, documentos pessoais (como CPF, RG, CNH,
          Carteira de Trabalho, passaporte e título de eleitor), endereço
          residencial ou comercial, telefone, e-mail e endereço IP.
          <br></br> Dados que coletamos e motivos da coleta:
          <br></br>
          Nosso site coleta e utiliza alguns dados pessoais de nossos usuários,
          de acordo com o disposto nesta seção.
          <br></br>
          1. Dados pessoais fornecidos expressamente pelo usuário
          <br></br>
          Nós coletamos os seguintes dados pessoais que nossos usuários nos
          fornecem expressamente ao utilizar nosso site:
          <br></br>
          a) Nome e sobrenome do usuário;<br></br>
          b) E-mail utilizado pelo usuário; <br></br>
          c) Identidade e CPF; <br></br>
          d) Data de nascimento;<br></br>
          e) Telefone;<br></br>
          f) Endereço;
          <br></br>A coleta destes dados ocorre nos seguintes momentos:
          Preenchimento de formulário padrão disponibilizado ao usuário para
          cadastro na plataforma e das seguintes forma: a) fornecidos pelo
          titular - quando são inseridos ou encaminhados ativamente pelo titular
          ao entrar em contato ou acessar os canais da Unimed do Brasil.
          Independentemente de quais dados o titular fornecer, nós apenas
          faremos uso daqueles efetivamente relevantes e necessários para o
          cumprimento das finalidades a ele declaradas, caso a caso; b) Dados
          coletados automaticamente - tais como: características do dispositivo
          de acesso, do navegador, IP (com data e hora), origem do IP,
          informações sobre cliques, páginas acessadas, termos de procura
          digitados em nossos portais, dentre outros. Para tal coleta,
          utilizamos algumas tecnologias padrões, como cookies, pixel tags,
          beacons e local shared objects, que têm o propósito de melhorar a
          experiência de navegação do titular nos serviços, de acordo com seus
          hábitos e suas preferências.
          <br></br>
          Os dados fornecidos por nossos usuários são coletados com as seguintes
          finalidades:
          <br></br>
          a) Para que possamos enviar as condições do produto/serviço
          contratado;<br></br>
          b) Para que possamos manter o usuário integralmente informado do
          andamento de solicitações e/ou reclamações eventualmente enviadas ao
          nosso conhecimento e providências; <br></br>
          c) para que possamos manter o usuário integralmente informado do
          andamento de solicitações, reclamações eventualmente enviadas ao nosso
          conhecimento e providências, e envio de informativos, divulgação de
          serviços e/ou produtos próprios ou de parceiros;
          <br></br>
          2. Dados sensíveis
          <br></br>
          Não serão coletados dados sensíveis de nossos usuários, assim
          entendidos aqueles definidos nos arts. 11 e seguintes da Lei de
          Proteção de Dados Pessoais (Lei n. º 13.709/2018). Assim, não haverá
          coleta de dados sobre origem racial ou étnica, convicção religiosa,
          opinião política, filiação a sindicato ou a organização de caráter
          religioso, filosófico ou político, dado referente à saúde ou à vida
          sexual, dado genético ou biométrico, quando vinculado a uma pessoa
          natural.
          <br></br>
          <strong> Cookies</strong>
          <br></br>
          Cookies são pequenos arquivos de texto baixados automaticamente em seu
          dispositivo quando você acessa e navega por um site. Eles servem,
          basicamente, para seja possível identificar dispositivos, atividades e
          preferências de usuários.
          <br></br>
          Os cookies não permitem que qualquer arquivo ou informação sejam
          extraídos do disco rígido do usuário, não sendo possível, ainda, que,
          por meio deles, se tenha acesso a informações pessoais que não tenham
          partido do usuário ou da forma como utiliza os recursos do site.
          <br></br>
          a. Cookies do site
          <br></br>
          Os cookies do site são aqueles enviados ao computador ou dispositivo
          do usuário e administrador exclusivamente pelo site. As informações
          coletadas por meio destes cookies são utilizadas para melhorar e
          personalizar a experiência do usuário, sendo que alguns cookies podem,
          por exemplo, ser utilizados para lembrar as preferências e escolhas do
          usuário, bem como para o oferecimento de conteúdo personalizado que
          possa lhe interessar.
          <br></br>
          b. Gestão de cookies
          <br></br>O usuário poderá se opor à utilização de cookies pelo site,
          bastando que os desative no momento em que começa a utilizar o
          serviço, seguindo as seguintes instruções, clicando nas opções
          “Aceito” ou “Não aceito”. A desativação de todos os cookies, no
          entanto, não será possível, uma vez que alguns deles são essenciais
          para que o site funcione corretamente.
          <br></br>
          Além disso, a desativação dos cookies que podem ser desabilitados
          poderá prejudicar a experiência do usuário, uma vez que informações
          utilizadas para personalizá-la deixarão de ser utilizadas.
          <br></br>
          <strong> Coleta de dados não previstos expressamente</strong>
          <br></br>
          Eventualmente, outros tipos de dados não previstos expressamente nesta
          Política de Privacidade poderão ser coletados, desde que sejam
          fornecidos com o consentimento do usuário, ou, ainda, que a coleta
          seja permitida com fundamento em outra base legal prevista em lei.
          <br></br>
          Em qualquer caso, a coleta de dados e as atividades de tratamento dela
          decorrentes serão informadas aos usuários do site.
          <br></br>
          <strong> Compartilhamento de dados pessoais com terceiros</strong>
          <br></br>
          Nós não compartilhamos seus dados pessoais com terceiros. Apesar
          disso, é possível que o façamos para cumprir alguma determinação legal
          ou regulatória, ou, ainda, para cumprir alguma ordem expedida por
          autoridade pública.
          <br></br>
          <strong>
             Por quanto tempo seus dados pessoais serão armazenados
          </strong>
          <br></br>
          Os dados pessoais coletados pelo site são armazenados e utilizados por
          período de tempo que corresponda ao necessário para atingir as
          finalidades elencadas neste documento e que considere os direitos de
          seus titulares, os direitos do controlador do site e as disposições
          legais ou regulatórias aplicáveis. Uma vez expirados os períodos de
          armazenamento dos dados pessoais, eles são removidos de nossas bases
          de dados ou anonimizados, salvo nos casos em que houver a
          possibilidade ou a necessidade de armazenamento em virtude de
          disposição legal ou regulatória.
          <br></br>
          <strong> Bases legais para o tratamento de dados pessoais</strong>
          <br></br>
          Uma base legal para o tratamento de dados pessoais nada mais é que um
          fundamento jurídico, previsto em lei, que justifica o justifica.
          Assim, cada operação de tratamento de dados pessoais precisa ter uma
          base legal a ela correspondente.
          <br></br>
          Nós tratamos os dados pessoais de nossos usuários nas seguintes
          hipóteses:
          <br></br>- mediante o consentimento do titular dos dados pessoais;
          <br></br>- para o exercício regular de direitos em processo judicial,
          administrativo ou arbitral;<br></br>- para a execução de contrato ou
          de procedimentos preliminares relacionados a contrato do qual seja
          parte o titular, a pedido do titular dos dados pessoais;<br></br>-
          quando necessário para atender aos interesses legítimos do controlador
          ou de terceiro.
          <br></br>
          1. Consentimento
          <br></br>
          Determinadas operações de tratamento de dados pessoais realizadas em
          nosso site dependerão da prévia concordância do usuário, que deverá
          manifestá-la de forma livre, informada e inequívoca. O usuário poderá
          revogar seu consentimento a qualquer momento, sendo que, não havendo
          hipótese legal que permita ou que demande o armazenamento dos dados,
          os dados fornecidos mediante consentimento serão excluídos.
          <br></br>
          Além disso, se desejar, o usuário poderá não concordar com alguma
          operação de tratamento de dados pessoais baseada no consentimento.
          Nestes casos, porém, é possível que não possa utilizar alguma
          funcionalidade do site que dependa daquela operação. As consequências
          da falta de consentimento para uma atividade específica são informadas
          previamente ao tratamento.
          <br></br>
          2. Execução de contrato Para a execução de contrato de compra e venda
          ou de prestação de serviços eventualmente firmado entre o site e o
          usuário, poderão ser coletados e armazenados outros dados relacionados
          ou necessários a sua execução, incluindo o teor de eventuais
          comunicações tidas com o usuário.
          <br></br>
          3. Legítimo interesse Para determinadas operações de tratamento de
          dados pessoais, nos baseamos exclusivamente em nosso interesse
          legítimo. Para saber mais sobre em quais casos, especificamente, nos
          valemos desta base legal, ou para obter mais informações sobre os
          testes que fazemos para termos certeza de que podemos utilizá-la,
          entre em contato com nosso Encarregado de Proteção de Dados Pessoais
          por algum dos canais informados nesta Política de Privacidade, na
          seção "Como entrar em contato conosco"(item 9).
          <br></br>
          <strong> Direitos do usuário</strong>
          <br></br>O usuário do site possui os seguintes direitos, conferidos
          pela Lei de Proteção de Dados Pessoais:<br></br>- confirmação da
          existência de tratamento; <br></br>- acesso aos dados; <br></br>-
          correção de dados incompletos, inexatos ou desatualizados; <br></br>-
          anonimização, bloqueio ou eliminação de dados desnecessários,
          excessivos ou tratados em desconformidade com o disposto na lei;{" "}
          <br></br>- portabilidade dos dados a outro fornecedor de serviço ou
          produto, mediante requisição expressa, de acordo com a regulamentação
          da autoridade nacional, observados os segredos comercial e
          industrial;-- eliminação dos dados pessoais tratados com o
          consentimento do titular, exceto nos casos previstos em lei; <br></br>
          - informação das entidades públicas e privadas com as quais o
          controlador realizou uso compartilhado de dados; <br></br>- informação
          sobre a possibilidade de não fornecer consentimento e sobre as
          consequências da negativa; <br></br>- revogação do consentimento.
          <br></br>É importante destacar que, nos termos da LGPD, não existe um
          direito de eliminação de dados tratados com fundamento em bases legais
          distintas do consentimento, a menos que os dados sejam desnecessários,
          excessivos ou tratados em desconformidade com o previsto na lei.
          <br></br>
          1. Como o titular pode exercer seus direitos Para garantir que o
          usuário que pretende exercer seus direitos é, de fato, o titular dos
          dados pessoais objeto da requisição, poderemos solicitar documentos ou
          outras informações que possam auxiliar em sua correta identificação, a
          fim de resguardar nossos direitos e os direitos de terceiros. Isto
          somente será feito, porém, se for absolutamente necessário, e o
          requerente receberá todas as informações relacionadas.
          <br></br>
          <strong>
             Medidas de segurança no tratamento de dados pessoais
          </strong>
          <br></br>
          Empregamos medidas técnicas e organizativas aptas a proteger os dados
          pessoais de acessos não autorizados e de situações de destruição,
          perda, extravio ou alteração desses dados. As medidas que utilizamos
          levam em consideração a natureza dos dados, o contexto e a finalidade
          do tratamento, os riscos que uma eventual violação geraria para os
          direitos e liberdades do usuário, e os padrões atualmente empregados
          no mercado por empresas semelhantes à nossa. Entre as medidas de
          segurança adotadas por nós, destacamos as seguintes:
          <br></br>- Protocolos de segurança e criptografia (Ex: Hashs
          criptográficos); <br></br>- Sistema de Controle de acesso; <br></br>-
          Firewall; <br></br>- Software de monitoramento;<br></br>- Múltiplo
          fator de autenticação;<br></br>- Gestão/Distribuição de patch e
          atualizações;<br></br>- Soluções de proteção de endpoint; <br></br>-
          DLP (Data Loss Prevention<br></br>- Desenvolvimento seguro e revisão
          de código<br></br>- Web Application Firewall e Database Firewall (WAF
          /DBF);
          <br></br>
          Seguimos, portanto, os mais elevados padrões técnicos de segurança da
          informação, a fim de que nos seja possível proteger os dados pessoais
          e não pessoais de nossos usuários.
          <br></br>
          Ainda que adote tudo o que está ao seu alcance para evitar incidentes
          de segurança, é possível que ocorra algum problema motivado
          exclusivamente por um terceiro - como em caso de ataques de hackers ou
          crackers ou, ainda, em caso de culpa exclusiva do usuário, que ocorre,
          por exemplo, quando ele mesmo transfere seus dados a terceiro. Assim,
          embora sejamos, em geral, responsáveis pelos dados pessoais que
          tratamos, nos eximimos de responsabilidade caso ocorra uma situação
          excepcional como essas, sobre as quais não temos nenhum tipo de
          controle.
          <br></br>
          De qualquer forma, caso ocorra qualquer tipo de incidente de segurança
          que possa gerar risco ou dano relevante para qualquer de nossos
          usuários, comunicaremos os afetados e a Autoridade Nacional de
          Proteção de Dados acerca do ocorrido, em conformidade com o disposto
          na Lei Geral de Proteção de Dados.
          <br></br>
          <strong> Reclamação a uma autoridade de controle</strong>
          <br></br>
          Sem prejuízo de qualquer outra via de recurso administrativo ou
          judicial, os titulares de dados pessoais que se sentirem, de qualquer
          forma, lesados, podem apresentar reclamação à Autoridade Nacional de
          Proteção de Dados.
          <br></br>
          <strong> Alterações nesta política</strong>
          <br></br>A presente versão desta Política de Privacidade foi
          atualizada pela última vez em: 11/08/2022
          <br></br>
          Reservamo-nos o direito de modificar, a qualquer momento, as presentes
          normas, especialmente para adaptá-las às eventuais alterações feitas
          em nosso site, seja pela disponibilização de novas funcionalidades,
          seja pela supressão ou modificação daquelas já existentes. Sempre que
          houver uma modificação, nossos usuários serão notificados acerca da
          mudança.
          <br></br>
          <strong> Como entrar em contato conosco</strong>
          <br></br>
          Para esclarecer quaisquer dúvidas sobre esta Política de Privacidade
          ou sobre os dados pessoais que tratamos, entre em contato com nosso
          Encarregado de Proteção de Dados Pessoais, por algum dos canais
          mencionados abaixo:
          <br></br>
          E-mail: contato@virtualticket.com.br
          <br></br>
          Telefone: (85) 3045-0402
          <br></br>
          Endereço postal: Empresarial Washington Soares - Av. Washington Soares
          N. 805
        </p>
      </div>
    </div>
  );
}
