/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./index.css";
import { useZendesk } from "../../../hooks/useZendesk";

export default function Meia() {
  const { hide } = useZendesk();
  const empresa = process.env.REACT_APP_NAME_EMPRESA;

  useEffect(() => {
    window.scrollTo(0, 0);
    hide();
  }, []);

  return (
    <div className="container info-containers mt-4 mb-4">
      <div className="row terms">
        {/* <div dangerouslySetInnerHTML={{ __html: meia }} /> */}
        <h1>LEI DA MEIA ENTRADA</h1>
        <p>
          Conforme a Lei Federal nº 12.933/2013 e o Decreto nº 8.537/2015, a responsabilidade pela disponibilização de ingressos com o benefício da meia-entrada é única e exclusivamente dos estabelecimentos de eventos, produtoras e promotoras de eventos. Ou seja, cabe ao organizador do evento definir os pontos de venda habilitados para venda de ingressos meia-entrada.
          A {empresa} é apenas um dos pontos de vendas escolhidos pela organização do evento.
          Para pontos de venda e bilheterias é necessária a comprovação do direito ao benefício da meia-entrada no ato da compra e no acesso ao evento.
          Caso o benefício não seja comprovado, o portador deverá complementar o valor do ingresso adquirido para o valor do ingresso integral, caso contrário o acesso ao evento não será permitido.
        </p>
        
        <h4><strong>Lei Federal 12.933/13</strong></h4>

        <p>
          <b>Art. 1º</b>  É assegurado aos estudantes o acesso a salas de cinema, cineclubes, teatros, espetáculos musicais e circenses e eventos educativos, esportivos, de lazer e de entretenimento, em todo o território nacional, promovidos por quaisquer entidades e realizados em estabelecimentos públicos ou particulares, mediante pagamento da metade do preço do ingresso efetivamente cobrado do público em geral.<br />
          <b>§ 1º</b> O benefício previsto no caput não será cumulativo com quaisquer outras promoções e convênios e, também, não se aplica ao valor dos serviços adicionais eventualmente oferecidos em camarotes, áreas e cadeiras especiais.<br />
          <b>§ 2º</b> Terão direito ao benefício os estudantes regularmente matriculados nos níveis e nas modalidades de educação e ensino previstos no Título V da Lei nº 9.394, de 20 de dezembro de 1996 , que comprovem sua condição de discente, mediante a apresentação da Carteira de Identificação Estudantil na aquisição do ingresso e na portaria do local de realização do evento. (Redação dada pela Medida Provisória nº 895, de 2019)<br />
          <b>§ 3º</b> (VETADO).<br />
          <b>§ 4º</b> (Revogado pela Medida Provisória nº 895, de 2019)<br />
          <b>§ 5º</b> (Revogado pela Medida Provisória nº 895, de 2019)<br />
          <b>§ 6º</b> (Revogado pela Medida Provisória nº 895, de 2019)<br />
          <b>§ 7º</b> (VETADO).<br />
          <b>§ 8º</b> Também farão jus ao benefício da meia-entrada as pessoas com deficiência, inclusive seu acompanhante quando necessário, sendo que este terá idêntico benefício no evento em que comprove estar nesta condição, na forma do regulamento.<br />
          <b>§ 9º</b> Também farão jus ao benefício da meia-entrada os jovens de 15 a 29 anos de idade de baixa renda, inscritos no Cadastro Único para Programas Sociais do Governo Federal (CadÚnico) e cuja renda familiar mensal seja de até 2 (dois) salários mínimos, na forma do regulamento.<br />
          <b>§ 10.</b> A concessão do direito ao benefício da meia-entrada é assegurada em 40% (quarenta por cento) do total dos ingressos disponíveis para cada evento.<br />
          <b>§ 11.</b> As normas desta Lei não se aplicam aos eventos Copa do Mundo FIFA de 2014 e Olimpíadas do Rio de Janeiro de 2016.<br />
          Link da lei completa:  <a target="_blank" rel="noopener noreferrer" href="http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933.htm">http://www.planalto.gov.br/ccivil_03/_Ato2011-2014/2013/Lei/L12933.htm</a> <br />
          Link do decreto completo: <a target="_blank" rel="noopener noreferrer" href=" http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Decreto/D8537.htm">http://www.planalto.gov.br/ccivil_03/_Ato2015-2018/2015/Decreto/D8537.htm</a>
        </p>

        <h1>
          Quem tem direito à meia-entrada e como comprová-la:
        </h1>

        <p>
          <h4><strong>ESTUDANTES</strong></h4>
          Estudantes do ensino Infantil, Fundamental, Médio, Técnico, Graduação ou Pós -Graduação.
          A comprovação será feita mediante apresentação da Carteira de Identificação Estudantil (CIE) emitida pelo Ministério da Educação, ANPG, UNE, UBES, entidades estudantis estaduais e municipais, pelos Diretórios Centrais dos Estudantes (DCEs) e pelos Centros e Diretórios Acadêmicos ou por outras entidades de ensino e associações representativas dos estudantes, conforme definido em ato do Ministro de Estado da Educação.
          A apresentação deste documento na entrada do evento é obrigatória.
          Caso você ainda não possua a novo documento do estudante e queira adquirir o seu, <a target="_blank" rel="noopener noreferrer" href="https://www.documentodoestudante.com.br/">clique aqui.</a>
        </p>

        <h4><strong>IDOSOS</strong></h4>
        <p>
          Pessoas a partir dos 60 (sessenta) anos de idade.
          A comprovação será feita mediante apresentação de documento oficial com foto.
          Este direito é assegurado por uma lei federal que não contempla aposentados com menos de 60 anos.
        </p>

        <h4><strong>PESSOAS COM NECESSIDADES ESPECIAIS (PNE) E ACOMPANHANTE</strong></h4>
        <p>
          Comprovada a necessidade, o PNE tem direito a um acompanhante que também usufruirá do benefício da meia-entrada.
          A comprovação será feita mediante apresentação do Cartão de Benefício de Prestação Continuada da Assistência Social da pessoa com deficiência ou de documento emitido pelo INSS que ateste a aposentadoria.
        </p>

        <h4><strong>JOVENS DE BAIXA RENDA</strong></h4>
        <p>
          Os jovens de baixa renda com idade entre 15 e 29 anos e que estejam inscritos no Cadastro Único para Programas Sociais do Governo Federal (CadÚnico) também dispõe do direito a meia-entrada.
          A comprovação será feita mediante apresentação da carteirinha Identidade Jovem do CadÚnico, acompanhada de documento de identificação oficial com foto.
        </p>

      </div>
    </div>    
  );
}
